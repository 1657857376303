import React, {useContext, useEffect, useRef, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import indexContext from "../../../../contexts/indexContext";
import {getMapIcons} from "../../../panelContent/map/mapFunctions/getMapIcons";
import mapboxgl from "mapbox-gl";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBackward, faForward} from "@fortawesome/pro-regular-svg-icons";
import ReactMapControls from "../../../panelContent/map/reactMapControls";

const TrackModalMap = ({track, handleTrack}) => {


    const {journey} = useContext(mainContext);

    const {darkMap} = useContext(indexContext)

    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);
    const [index, setIndex] = useState();

    const forEachLayer = (text, cb) => {
        map.getStyle().layers.forEach((layer) => {
            if (!layer.id.includes(text)) return;
            cb(layer);
        });
    }

    const generateMapLine = (journey) => {
        const lineData = []
        journey?.features.map(obj => {
            if(obj.geometry.coordinates[0] !== 0){
                lineData.push(obj.geometry.coordinates)
            }
        });
        return lineData;
    }

    const changeStyle = (style) => {
        if (map){
            const savedLayers = [];
            const savedSources = {};
            const layerGroups = [
                'line-layer',
                'vehicle-points-layer'
            ];

            layerGroups.forEach((layerGroup) => {
                forEachLayer(layerGroup, (layer) => {
                    savedSources[layer.source] = map.getSource(layer.source).serialize();
                    savedLayers.push(layer);
                });
            });

            map.setStyle(`mapbox://styles/${style}`);

            setTimeout(() => {

                let custIcons = getMapIcons();

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.src = icon.src;

                    customIcon.onload = () => {
                        if (!map.hasImage(icon.name)) map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    }

                });

                Object.entries(savedSources).forEach(([id, source]) => {
                    try {
                        map.addSource(id, source);
                    } catch (e){
                        // console.log(e)
                        // console.log(source)
                    }
                });

                savedLayers.forEach((layer) => {

                    const mapLayer = map.getLayer(layer.id);

                    if(typeof mapLayer === 'undefined') {
                        map.addLayer(layer);
                    }
                });

                map.setPaintProperty('line-layer', 'line-color', getComputedStyle(document.body).getPropertyValue('--journey-line-colour'))
            }, 1500);
        }
    }



    useEffect(() => {
        if(map){
            if (darkMap){
                changeStyle('fleetfocus/cl74ogqly001b14n15u5x24zn')
            } else {
                changeStyle('fleetfocus/clyreqdlj00d701qr81uf1gsw')
            }
        }
    }, [darkMap])




    useEffect(() => {
        if (journey?.features?.length > 0 && track){
            for (let i = 0; i < journey.features?.length; i++) {
                if (journey.features[i].properties.time === track.properties.time) {
                    setIndex(i);
                    break;
                }
            }
        }
    }, [journey, track]);



    useEffect(() => {


        mapboxgl.accessToken =

            "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";

        // const mapStyle = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';
        // const mapStyle = 'mapbox://styles/fleetfocus/ckzd4tbpk005e14qe1hs9tqmd?optimize=true';
        const mapStyle = darkMap ? 'mapbox://styles/fleetfocus/cl74ogqly001b14n15u5x24zn'
            : 'mapbox://styles/fleetfocus/clyreqdlj00d701qr81uf1gsw';


        // const markerRef = useRef(new mapboxgl.Marker(el));



        const initializeMap = ({setMap, mapContainer}) => {

            const map = new mapboxgl.Map({
                container: mapContainer.current,
                // style: 'mapbox://styles/mapbox/streets-v11',
                style: mapStyle,
                center: track?.geometry?.coordinates,
                zoom: 16
            });






            map.on('load', () => {

               //map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);

                let custIcons = getMapIcons();

                custIcons.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.src = icon.src;

                    customIcon.onload = () => {
                        //map?.removeImage(icon.name);
                        map?.addImage(icon.name, customIcon, {sdf: icon.sdf})
                    }

                });




            setMap(map);
                map.resize();



                map.scrollZoom.setWheelZoomRate(2);

                map.dragRotate.disable();
                map.touchZoomRotate.disableRotation();

                if (journey && track) {
                    map.addSource('track-data-point', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [track]
                        },
                    });


                    map.addSource('track-data-line', {
                        type: 'geojson',
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: generateMapLine(journey)
                            }
                        }
                    });

console.log(journey)
                    // now add the layer, and reference the data source above by name

                    map.addLayer({
                        id: 'vehicle-points-layer',
                        source: 'track-data-point',
                        type: 'symbol',
                        layout: {
                            'icon-image': [
                                'case',

                                ['==', ['get', 'time'], journey?.features?.[0]?.properties?.time], 'jStartFlag',



                                ['all',
                                    ['==', ['get', 'time'], journey?.features?.[journey?.features?.length - 1]?.properties?.time],
                                    ['==', ['get', 'completed'], true]
                                ],
                                'jEndedFlag',

                                ['all',
                                    ['==', ['get', 'time'], journey?.features?.[journey?.features?.length - 1]?.properties?.time],
                                    ['==', ['coalesce', ['get', 'completed'], false], false],
                                ],
                                'jInProgressArrow',

                                'lpArrow'
                            ],

                            'icon-ignore-placement': false,
                            'icon-allow-overlap': true,
                            'text-allow-overlap': true,
                            'icon-rotate':  [
                                'case',

                                ['==', ['get', 'time'], journey?.features?.[0]?.properties?.time], 0,


                                ['all',
                                    ['==', ['get', 'time'], journey?.features?.[journey?.features?.length - 1]?.properties?.time],
                                    ['==', ['get', 'completed'], true]
                                ],
                                0,

                                ['all',
                                    ['==', ['get', 'time'], journey?.features?.[journey?.features?.length - 1]?.properties?.time],
                                    ['==', ['coalesce', ['get', 'completed'], false], false],
                                ],
                                0,

                                ['get', 'head']
                            ],
                            "icon-size": ['interpolate', ['linear'], ['zoom'], 10, 1, 15, 1],                            'icon-anchor': 'center',
                        },
                        paint: {
                            'icon-color': 'white',
                            'text-color': '#ffffff'
                        },
                    },);




                    map.addLayer({
                        id: 'line-layer',
                        type: 'line',
                        source: 'track-data-line',
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round',
                            'visibility': 'visible'
                        },
                        paint: {
                            'line-color': getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
                            'line-width': {
                                base: 10,
                                stops: [
                                    [1, 5],
                                    [15, 12]
                                ]
                            },
                            'line-opacity': {
                                stops: [
                                    [1, 1],
                                    [14, 0.5],
                                    [20, 0.0]
                                ]
                            },

                        },

                    }, 'vehicle-points-layer',);      // 'eventsLayer');

                    // const bounds = generateMapLine(event).reduce(function (b, coord) {
                    //     return b.extend(coord);
                    // }, new mapboxgl.LngLatBounds(generateMapLine(event)[0], generateMapLine(event)[0]));
                    //
                    // // console.log(bounds);
                    // let padding = {};
                    //
                    // map.fitBounds(bounds, {
                    //     padding: {bottom: 50, left: 50, top: 50, right: 50}, maxZoom: 18
                    // });
                } else {
                    // const marker1 = new mapboxgl.Marker()
                    //     .setLngLat([event.lng, event.lat])
                    //     .addTo(map);
                    //
                    //
                    // map.flyTo({
                    //     center: [event.lng, event.lat],
                    //     zoom: 15,
                    //     speed: 2
                    // });
                }
            });
        };

        if (!map) initializeMap({setMap, mapContainer});
    }, [map]);


    useEffect(() => {
        if (track && map){
            map.getSource('track-data-point').setData({
                type: 'FeatureCollection',
                features: [track]
            });

            map.easeTo({
                center: track.geometry.coordinates
            });
        }
    }, [track, journey]);



    return (
        <div style={{display: 'flex', height: '50vh', flexDirection: 'column', width: '100%', paddingTop: '10px'}}>
            <div className="mapContainer" id="mapContainer"
                 style={{flexDirection: 'column', position: 'relative', display: 'flex'}}>
                <div ref={el => (mapContainer.current = el)} style={{
                    flex: "1", position: 'relative', height: '100%', width: '100%'
                }}/>
                <div style={{position: 'absolute', top: 10, right: 10}} className="customControls">
                    <ReactMapControls map={map} changeStyle={changeStyle} parent={'trackModal'}/>
                </div>
            </div>


            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>

                <div onClick={(e) => {
                    e.stopPropagation();
                    if (index > 0) handleTrack(journey?.features[index - 1])
                }} style={{cursor: 'pointer'}}>
                    <FontAwesomeIcon icon={faBackward}/>
                    &nbsp;Previous track
                </div>

                <div onClick={(e) => {
                    e.stopPropagation();
                    if (index + 1 < journey?.features?.length) handleTrack(journey?.features[index + 1])
                }} style={{cursor: 'pointer'}}>
                    Next track
                    &nbsp;<FontAwesomeIcon icon={faForward}/>
                </div>


            </div>


        </div>
    )
}

export default TrackModalMap
