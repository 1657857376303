import {API_URL} from '../Constants.js'
import clearStorage from "../functions/localStorage/clearStorage";
import {getDrivingStatus} from "../functions/drivingStatus";


const fetchVehicles = (params = {}) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify(params)
    };




    return fetch(API_URL + '/f-vehicles', requestOptions)
        .then((response) => {
            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
                const vehicles = [];
                for (let i = 0; i < data.length; i++) {
                    const id = i;

                    let coords = [
                        data[i].lastLocation?.lng ? data[i].lastLocation?.lng : 0,
                        (data[i]?.lastLocation?.lat > 90 || data[i]?.lastLocation?.lat < -90) ? 0 : data[i]?.lastLocation?.lat ?
                            data[i]?.lastLocation?.lat : 0,
                    ]

                    const theTimeAtFetch = new Date(params?.lastModified).getTime();
                    const drivingStatus = getDrivingStatus(data[i]?.lastHeartbeat, data[i].lastLocation?.ac, theTimeAtFetch)


                    const obj = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: coords,
                        },
                        properties: {
                            id,
                            dn: data[i].dn,
                            head: data[i]?.lastLocation?.head,
                            registration: data[i].registration,
                            lastLocation: data[i]?.lastLocation,
                            lastHeartbeat: data[i]?.lastHeartbeat,
                            deviceStatus: data[i].deviceStatus,
                            drivingStatus: drivingStatus,
                            endAddress: data[i].endAddress,
                            driver: data[i].driver,
                            fleetId: data[i].fleetId,
                            tags: data[i].tags
                        },
                    };

                    vehicles.push(obj)
                }

                const theFeatures = vehicles.sort((a,b) => {

                    if (!a?.properties?.registration) {
                        // Change this values if you want to put `null` values at the end of the array
                        return -1;
                    }

                    if (!b?.properties?.registration) {
                        // Change this values if you want to put `null` values at the end of the array
                        return +1;
                    }

                    return a?.properties?.registration.localeCompare(b?.properties?.registration);



                    a?.properties?.registration.localeCompare(b?.properties?.registration)
                });

                return Promise.resolve({
                    type: 'FeatureCollection',
                    features: theFeatures
                });
            }
        );
}


export default fetchVehicles;
