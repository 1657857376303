import React, {useContext, useEffect, useState} from 'react';
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
import gridContext from "../../../../contexts/gridContext";
import {findDataByKey, getStoreData} from "../../../../../functions/db";
import mainContext from "../../../../contexts/mainContext";
import DriverComparator from "./driverComparator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChartSimple} from "@fortawesome/pro-regular-svg-icons";


const EventParamChips = ({}) => {

const {eventParams, removeParam, handleEventParams, eventCounts, tagLib, comparatorData} = useContext(gridContext);
const {disableDateChange, vehicle} = useContext(mainContext)


    const [showComparator, setShowComparator] = useState(true)


    const handleShowComparator = (v) => setShowComparator(v)

    const compartorLabel = () => {
        return <div><FontAwesomeIcon icon={faChartSimple} style={{marginRight: '5px', fontSize: '16px'}}/>&nbsp;Insight chart</div>
    }


    return (
        <div>

            {eventParams?.length > 0 &&
                <div style={{marginTop: '100px', padding: '10px 10px', height: '50px'}}>

                    {(eventParams?.length > 0 && !vehicle) &&
                        <Button label={compartorLabel()} disabled={!comparatorData}
                                onClick={() => setShowComparator(!showComparator)}
                                style={{
                                    cursor: 'pointer',
                                    height: '28px',
                                    padding: '0px 10px',
                                    borderRadius: '2px',
                                    color: showComparator ? 'var(--v-list-button-text-selected)' : 'var(--v-list-button-text)',
                                    margin: '3px',
                                    background: showComparator ? 'var(--v-list-button-selected)' : 'var(--v-list-button)'
                                }} className={'activeChips'}/>

                    }

                    {eventParams?.map(param => (
                        <Chip key={param} label={tagLib?.[param]?.displayName} className="activeChips p-chip" removable
                              onRemove={() => removeParam(param)} disabled={disableDateChange}/>
                    ))}

                    {eventParams?.length > 0 &&
                        <Button label={'Reset'} disabled={disableDateChange}
                                onClick={() => handleEventParams([])}
                                style={{
                                    cursor: 'pointer',
                                    height: '28px',
                                    padding: '0px 10px',
                                    borderRadius: '2px', color: 'var(--v-list-button-text)',
                                    margin: '3px', background: 'var(--v-list-button)'
                                }} className={'activeChips'}/>


                    }
                </div>

            }

            {showComparator && eventParams?.length > 0 && !vehicle &&
                <DriverComparator handleShowComparator={handleShowComparator}/>
            }

        </div>
    )

}

export default EventParamChips