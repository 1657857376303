import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import { Tooltip } from 'primereact/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignalStream, faAddressCard, faBuildings, faSdCard, faCompassSlash} from '@fortawesome/pro-light-svg-icons'
import {faGears} from "@fortawesome/pro-light-svg-icons";
import {faChevronLeft, faFilter} from "@fortawesome/pro-light-svg-icons";
import {friendlyFleetName} from "../../../../functions/formatting/friendlyFleetName";
import {formatDateOnly} from "../../../../functions/formatting/formatDate";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import {faX} from "@fortawesome/pro-light-svg-icons/faX";
import {fetchBlockedEndpoints} from "../../../../functions/fetchBlockedEndpoints";
import {copyToClipboard} from "../../../../functions/copyToClipboard";
import EditTags from "../../vehicleList/editTags";
import {Button} from "primereact/button";
import {findDataByKey} from "../../../../functions/db";
import VehicleLiveLocationV2 from "../../vehicleList/vehicleLiveLocationV2";
import {faLocationArrow} from "@fortawesome/free-solid-svg-icons";
import {faSquareParking} from "@fortawesome/pro-regular-svg-icons";
import {lastTrackTime} from "../../../../functions/formatting/lastTrackTime";
import {truncate} from "../../../../functions/formatting/truncate";



const VehicleDetail = ({}) => {

    const {winWidth, fleets} = useContext(indexContext);

    const {vehicle, date, handleLivestream, openModal, calendar, handleVehicle, deviceDetails,
    handleVehicleFilterParams, vehicleFilterParams, toast} = useContext(mainContext)

    const [recForDay, setRecForDay] = useState();
    const [latestVehicle, setLatestVehicle] = useState(vehicle);





    const divider = {background: 'var(--surface-border)', height: '30px', width: '1px'}

    const vStyle = {
        width: '100%',
        padding: '5px 0px 5px 2px',
        borderBottom: 'solid 1px var(--surface-border)',
        borderLeft: 'none',
        display: 'flex',
        alignItems:'center',
        background: 'var(--surface-ground)',
        height: '45px'
    }

    const applyTag = (val) => {
        handleVehicleFilterParams({
            fleetsToShow: vehicleFilterParams.fleetsToShow.add(val),
            chips: vehicleFilterParams.chips,
            search: vehicleFilterParams.search,
            drivingStatus: vehicleFilterParams.drivingStatus
        })
    }

    const interval = useRef();
    const timeout = useRef();

    const setMyTimer = useCallback(() => {
        clearInterval(interval?.current);
        clearTimeout(timeout?.current);


        var toExactMinute = 60000 - (new Date().getTime() % 60000);

        timeout.current = setTimeout(async () => {
            const v = await findDataByKey('vehicles', vehicle?.properties?.dn)
            setLatestVehicle(v)
            interval.current = setInterval(async () => {
                const v = await findDataByKey('vehicles', vehicle?.properties?.dn)
                setLatestVehicle(v)
            }, 60000)
        }, toExactMinute)
    }, [vehicle?.properties?.dn])


    useEffect(async () => {
        if (vehicle?.properties?.dn){
            setLatestVehicle(vehicle);
            setMyTimer()
        } else {
            clearTimeout(timeout?.current);
            clearInterval(interval?.current);
        }
    }, [vehicle]);

    useEffect(() => {
        return () => clearInterval(interval?.current);
    }, []);



    useEffect(() => {
        if(date){
            if (calendar?.modRecordingHistory.filter(item => formatDateOnly(date) === formatDateOnly(item.st) ||
                formatDateOnly(date) === formatDateOnly(item.et)).length > 0){
                setRecForDay(true);
            }else {
                setRecForDay(false);
            }
        }
    }, [date])

    const getRotation = (val) => {
        if (latestVehicle?.properties?.drivingStatus === 'driving'){
            const vListEle = document.getElementById("lastTrack" + latestVehicle?.properties?.dn);
            const rotation = vListEle?.getAttribute('rotationnation')
            return val === rotation ? val : rotation
        } else {
            return 0;
        }

    }



    if (vehicle){
        return(
            <div style={vStyle}>

                <div style={{padding: '0px 10px 0px 6px'}}>
                    

                    <span style={{color: latestVehicle?.properties?.drivingStatus === 'driving' ? 'green' :
                            latestVehicle?.properties?.drivingStatus === 'idling' ? 'orange' : 'red'}}>
                          {(latestVehicle?.properties?.drivingStatus === 'driving') ?

                              <span className={"lastTrackDetailsBar" + latestVehicle?.properties?.dn} data-pr-position="bottom">
                                    <FontAwesomeIcon icon={faLocationArrow} size='lg'
                                                     transform={{rotate: getRotation(latestVehicle?.properties?.lastLocation?.head - 45)}}
                                    />
                                  <Tooltip target={".lastTrackDetailsBar" + latestVehicle?.properties?.dn}>
                                  {lastTrackTime(latestVehicle, 'Last track was ', ' ago')}
                                  </Tooltip>
                              </span>


                              :
                              <React.Fragment>
                                  {latestVehicle?.properties?.lastLocation ?
                                      <span className={"parkedSinceDetailsBar" + latestVehicle?.properties?.dn} data-pr-position="bottom">
                                            <FontAwesomeIcon icon={faSquareParking} size="lg"
                                            />
                                            <Tooltip target={".parkedSinceDetailsBar" + latestVehicle?.properties?.dn}>
                                                {lastTrackTime(latestVehicle, 'Parked for ', '')}
                                            </Tooltip>
                                      </span>
                                      :
                                      <span className={"parkedSinceDetailsBar" + latestVehicle?.properties?.dn} data-pr-position="bottom">
                                            <FontAwesomeIcon icon={faCompassSlash} size="lg"
                                            />
                                            <Tooltip target={".parkedSinceDetailsBar" + latestVehicle?.properties?.dn}>
                                                Device missing
                                            </Tooltip>
                                      </span>
                                  }
                              </React.Fragment>

                          }

                    </span>

                    <span className={"registration" + vehicle.properties.id} onClick={() => {copyToClipboard(vehicle.properties.dn, 'Device number', toast)}}
                      style={{fontWeight: 'bold',fontSize: '18px',marginRight: '20px',marginLeft: '5px'}}>
                        {vehicle.properties.registration}
                    </span>
                    <Tooltip target={".registration" + vehicle.properties.id}>{vehicle.properties.dn}</Tooltip>
                    <br/>
                    {deviceDetails?.vehicleDvlaData &&


                    <span style={{
                        marginRight: '5px',
                        marginTop: '2px',
                        maxWidth: '100%',
                        marginLeft: '1px',
                        fontSize: '12px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'inline-block'
                    }}>
                           {deviceDetails?.vehicleDvlaData?.DvlaVehicleMake &&
                               <React.Fragment>{deviceDetails?.vehicleDvlaData?.DvlaVehicleMake}</React.Fragment>}
                        {deviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Make &&
                            <React.Fragment>{deviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Make}</React.Fragment>}
                        &nbsp;-&nbsp;
                        {deviceDetails?.vehicleDvlaData?.DvlaVehicleModel &&
                            <React.Fragment>{deviceDetails?.vehicleDvlaData?.DvlaVehicleModel}</React.Fragment>}
                        {deviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Model &&
                            <React.Fragment>{deviceDetails?.vehicleDvlaData?.ClassificationDetails?.Dvla?.Model}</React.Fragment>}
                        </span>
                    }
                </div>


                <button className="p-dialog-header-icon p-dialog-header-close p-link"
                        onClick={() => {
                            handleVehicle(null)
                        }}
                        style={{
                            alignSelf: 'center', width: '2rem', height: '2rem', borderRadius: '50%',
                            textAlign: 'center', marginRight: '10px'
                        }}>
                    <FontAwesomeIcon icon={faX} style={{color: 'var(--font-color)'}}/>
                </button>


                <div style={divider}>&nbsp;</div>


                {winWidth > 800 &&
                    <React.Fragment>
                        {latestVehicle?.properties?.driver &&
                            <React.Fragment>
                                <div style={{padding: '0px 15px 0px 15px'}}>
                                    <div style={{fontSize: '9px'}}>
                                        Driver:
                                    </div>
                                    <div style={{fontSize: '13px'}}>
                                        {truncate(latestVehicle?.properties?.driver, 20, 18)}
                                    </div>
                                </div>
                                <div style={divider}>&nbsp;</div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }





                {winWidth > 800 &&
                    <React.Fragment>
                        {vehicle.properties.fleetId &&
                            <React.Fragment>
                                <div style={{padding: '0px 15px 0px 15px'}}>
                                    <div style={{fontSize: '9px'}}>
                                        Fleet:
                                    </div>
                                    <div style={{fontSize: '13px'}}>
                                        {friendlyFleetName(vehicle?.properties?.fleetId, fleets)}
                                    </div>
                                </div>
                                <div style={divider}>&nbsp;</div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }

                {winWidth > 800 &&
                    <React.Fragment>
                        {vehicle.properties.lastLocation &&
                            <React.Fragment>
                                <div style={{padding: '0px 15px 0px 15px'}}>
                                    <div style={{fontSize: '9px'}}>
                                        Location:
                                    </div>
                                    <div style={{fontSize: '13px'}}>
                                        <VehicleLiveLocationV2 d={latestVehicle?.properties}/>
                                    </div>
                                </div>
                                <div style={divider}>&nbsp;</div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }


                <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '20px',
                    alignItems: 'middle'
                }}>


                    <button  className={"p-button p-button-sm eventFilterButton"}
                        style={{background: "var(--v-list-button)", color: 'var(--v-list-button-text)', marginRight: '4px'}}
                        onClick={() => openModal('displayDeviceDetailsModal')}>

                       <span className="p-button-label">
                            <FontAwesomeIcon icon={faGears}/>
                           {winWidth > 800 &&
                               <span><br />Settings</span>

                           }
                       </span>

                    </button >


                    {handleLivestream && !fetchBlockedEndpoints().includes('live-preview') &&
                        <button
                            className={"p-button p-button-sm eventFilterButton"}

                            style={{
                            background: latestVehicle?.properties?.drivingStatus && latestVehicle?.properties?.drivingStatus !== 'parked' ? 'var(--v-list-button-selected)' : "var(--v-list-button)",
                            color: latestVehicle?.properties?.drivingStatus && latestVehicle?.properties?.drivingStatus !== 'parked' ? 'var(--v-list-button-text-selected)' : "var(--v-list-button-text)",
                            opacity: latestVehicle?.properties?.drivingStatus !== 'parked' ? 1 : 0.5
                        }}
                             onClick={() => {

                                 if (latestVehicle?.properties?.drivingStatus !== 'parked') {
                                     handleLivestream(vehicle)
                                 }

                             }}>

                            <span className="p-button-label">
                                <FontAwesomeIcon icon={faSignalStream}/>
                                {winWidth > 800 &&
                                    <span><br/>LiveCam</span>

                                }
                            </span>

                        </button >
                    }


                    {/*<div onClick={() => {*/}
                    {/*    openModal('displayDeviceDetailsModal')*/}
                    {/*}}*/}
                    {/*     style={{*/}
                    {/*         fontSize: '28px',*/}
                    {/*         paddingLeft: '15px',*/}
                    {/*         height: '100%',*/}
                    {/*         cursor: 'pointer',*/}
                    {/*         paddingTop: '5px'*/}
                    {/*     }}>*/}
                    {/*    <FontAwesomeIcon icon={faGears}/>*/}
                    {/*</div>*/}
                </div>


            </div>
        )
    } else {
        return <div></div>
    }
}

export default VehicleDetail;


// #F8F2F8
